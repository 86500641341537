import { Link, useNavigate } from 'react-router-dom';
import './styles/Footer.scss'
import { useState } from 'react';
import InfoBanner from './InfoBanner';

const banners = {businessConditions: "businessConditions", gdpr: "gdpr"}

export default function Footer({inner} : any) {
    const navigate = useNavigate();
    const [banner, setBanner] = useState<string | null>(null);

    return (
        <>
        {
            banner === banners.businessConditions &&
            <InfoBanner title="Obchodní podmínky" click={() => setBanner(null)}>
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas tristique vitae ex at malesuada. Fusce sodales ut felis sit amet consequat. Quisque at urna quis neque sollicitudin pellentesque a in felis. Proin tempus eget diam sit amet convallis. Nulla porta varius lacus, ac condimentum lorem lacinia at. Nam bibendum sodales urna, id bibendum orci volutpat at. Aliquam pellentesque, urna vitae tincidunt pretium, quam ante iaculis dui, id posuere justo tortor a mi.

Etiam et libero consequat, facilisis quam sit amet, tristique augue. Fusce fermentum, dolor sit amet mattis tincidunt, magna magna sodales tortor, et pulvinar nisl sem vel est. Praesent mattis malesuada eleifend. Ut libero tellus, mollis in finibus in, porta nec mi. Proin semper ipsum at purus condimentum efficitur vel ac eros. Interdum et malesuada fames ac ante ipsum primis in faucibus. Proin mollis bibendum augue, quis sagittis elit euismod nec. Sed tempus mauris id est viverra fermentum. Morbi iaculis lectus ac odio semper, sed tempor orci mattis. Morbi lectus ipsum, imperdiet eget odio sed, pharetra lobortis odio.

Sed molestie turpis eu risus aliquet, sit amet aliquam est molestie. Quisque sapien eros, consectetur tempor felis eget, volutpat congue justo. Cras porttitor orci sed risus ullamcorper, nec sagittis leo mollis. Sed ultricies consectetur ex vitae iaculis. Curabitur pharetra dignissim nisi in bibendum. Nulla facilisi. Cras eget libero eget erat blandit convallis. Suspendisse potenti. Quisque at sollicitudin dui, non scelerisque nulla. Donec convallis ex ut mauris aliquam, in varius urna porta. Suspendisse tincidunt nulla vel nibh convallis, quis pulvinar massa efficitur. Sed nec consequat nulla. Donec quis velit libero. Maecenas aliquam, purus a malesuada ultricies, eros neque tempor mauris, eget accumsan felis magna sed sapien. Nulla blandit risus efficitur neque pellentesque, at semper justo consectetur. Praesent consectetur, arcu nec sagittis blandit, metus lacus mollis ante, in condimentum dolor risus ac ex.

Mauris ornare tellus eget leo viverra, non sollicitudin dolor placerat. Integer volutpat felis diam, eu interdum velit auctor vitae. Vestibulum at hendrerit mauris, consequat porttitor nulla. Sed sed pulvinar ligula. Sed id malesuada sapien. In iaculis cursus luctus. Mauris pretium neque ac dolor ultrices condimentum. Pellentesque laoreet nunc eu rhoncus sodales. In hac habitasse platea dictumst.

Cras posuere semper ligula, tristique scelerisque felis blandit id. Ut interdum a augue in feugiat. Morbi sagittis, erat id aliquam hendrerit, elit risus viverra diam, quis aliquet ex lacus id arcu. Phasellus laoreet augue a turpis pulvinar, vitae facilisis metus aliquet. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Aliquam quis ipsum non erat commodo mollis non id magna. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;

Donec tempor, lacus non viverra hendrerit, ipsum risus vehicula magna, ut sollicitudin lectus libero at est. Donec sollicitudin tincidunt justo, at finibus purus maximus in. Proin magna dolor, fermentum at nunc nec, vehicula lacinia arcu. Suspendisse consequat mi tortor, molestie scelerisque felis egestas sed. Etiam ac lectus aliquet, cursus enim at, porttitor mi. Cras et augue lorem. Nullam ac mi vel eros varius porta et sit amet erat.

Ut molestie elit eros. Phasellus commodo turpis non vulputate ultrices. Nunc molestie justo semper, consectetur ligula vitae, scelerisque ante. Suspendisse non vehicula massa, vel venenatis felis. Aliquam erat volutpat. Aenean vel mollis ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent tincidunt ullamcorper nisi, eget molestie metus.

Nulla consectetur dolor eu diam mollis tempus. Nunc rhoncus congue enim, in sagittis leo aliquet ac. Cras consectetur, erat quis blandit faucibus, libero mauris maximus dolor, non varius nisi tellus et nibh. Praesent non elit mi. Nam lobortis pulvinar dui, congue cursus justo pretium ut. Pellentesque volutpat ut nulla at vestibulum. In at sem suscipit, finibus eros eget, tristique tellus. Suspendisse varius a tortor vel cursus. Nullam sodales ac orci a tempus. Nunc luctus lacinia odio, ac volutpat neque pretium et. Nunc scelerisque posuere erat. Donec nulla neque, convallis a volutpat in, lacinia non purus.

Nunc nec semper justo, nec sodales nisl. Maecenas porttitor, arcu eu efficitur scelerisque, lacus neque pellentesque mi, eu lacinia risus est ac nunc. Cras id porttitor leo, sed dapibus mauris. Cras porttitor justo ut arcu rutrum fringilla. In mattis venenatis ipsum vel ultricies. Aenean consectetur odio non est pretium semper. Fusce rhoncus eros nisl, vel euismod dolor egestas ullamcorper. Aliquam erat volutpat. Aliquam blandit urna ac massa euismod, quis auctor dui ultrices. Donec pretium enim ac rhoncus commodo. Etiam convallis lorem eget mi ornare, non varius neque hendrerit.

Sed mauris ex, euismod ultrices dui vel, maximus facilisis leo. Vestibulum viverra pellentesque tempus. Aliquam in sapien in elit pretium aliquam vel sed lectus. Nunc at elementum felis. Sed vel diam non est suscipit fringilla. Phasellus rutrum, elit eu consectetur tempus, nisl metus tincidunt nisi, ut ultrices risus felis non purus. Duis in ex vitae ex porta mollis. In leo purus, fringilla sit amet lacinia non, iaculis ut dolor. 
            </InfoBanner>
        }
        {
            banner === banners.gdpr &&
            <InfoBanner title="GDPR" click={() => setBanner(null)}>
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas tristique vitae ex at malesuada. Fusce sodales ut felis sit amet consequat. Quisque at urna quis neque sollicitudin pellentesque a in felis. Proin tempus eget diam sit amet convallis. Nulla porta varius lacus, ac condimentum lorem lacinia at. Nam bibendum sodales urna, id bibendum orci volutpat at. Aliquam pellentesque, urna vitae tincidunt pretium, quam ante iaculis dui, id posuere justo tortor a mi.

Etiam et libero consequat, facilisis quam sit amet, tristique augue. Fusce fermentum, dolor sit amet mattis tincidunt, magna magna sodales tortor, et pulvinar nisl sem vel est. Praesent mattis malesuada eleifend. Ut libero tellus, mollis in finibus in, porta nec mi. Proin semper ipsum at purus condimentum efficitur vel ac eros. Interdum et malesuada fames ac ante ipsum primis in faucibus. Proin mollis bibendum augue, quis sagittis elit euismod nec. Sed tempus mauris id est viverra fermentum. Morbi iaculis lectus ac odio semper, sed tempor orci mattis. Morbi lectus ipsum, imperdiet eget odio sed, pharetra lobortis odio.

Sed molestie turpis eu risus aliquet, sit amet aliquam est molestie. Quisque sapien eros, consectetur tempor felis eget, volutpat congue justo. Cras porttitor orci sed risus ullamcorper, nec sagittis leo mollis. Sed ultricies consectetur ex vitae iaculis. Curabitur pharetra dignissim nisi in bibendum. Nulla facilisi. Cras eget libero eget erat blandit convallis. Suspendisse potenti. Quisque at sollicitudin dui, non scelerisque nulla. Donec convallis ex ut mauris aliquam, in varius urna porta. Suspendisse tincidunt nulla vel nibh convallis, quis pulvinar massa efficitur. Sed nec consequat nulla. Donec quis velit libero. Maecenas aliquam, purus a malesuada ultricies, eros neque tempor mauris, eget accumsan felis magna sed sapien. Nulla blandit risus efficitur neque pellentesque, at semper justo consectetur. Praesent consectetur, arcu nec sagittis blandit, metus lacus mollis ante, in condimentum dolor risus ac ex.

Mauris ornare tellus eget leo viverra, non sollicitudin dolor placerat. Integer volutpat felis diam, eu interdum velit auctor vitae. Vestibulum at hendrerit mauris, consequat porttitor nulla. Sed sed pulvinar ligula. Sed id malesuada sapien. In iaculis cursus luctus. Mauris pretium neque ac dolor ultrices condimentum. Pellentesque laoreet nunc eu rhoncus sodales. In hac habitasse platea dictumst.

Cras posuere semper ligula, tristique scelerisque felis blandit id. Ut interdum a augue in feugiat. Morbi sagittis, erat id aliquam hendrerit, elit risus viverra diam, quis aliquet ex lacus id arcu. Phasellus laoreet augue a turpis pulvinar, vitae facilisis metus aliquet. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Aliquam quis ipsum non erat commodo mollis non id magna. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;

Donec tempor, lacus non viverra hendrerit, ipsum risus vehicula magna, ut sollicitudin lectus libero at est. Donec sollicitudin tincidunt justo, at finibus purus maximus in. Proin magna dolor, fermentum at nunc nec, vehicula lacinia arcu. Suspendisse consequat mi tortor, molestie scelerisque felis egestas sed. Etiam ac lectus aliquet, cursus enim at, porttitor mi. Cras et augue lorem. Nullam ac mi vel eros varius porta et sit amet erat.

Ut molestie elit eros. Phasellus commodo turpis non vulputate ultrices. Nunc molestie justo semper, consectetur ligula vitae, scelerisque ante. Suspendisse non vehicula massa, vel venenatis felis. Aliquam erat volutpat. Aenean vel mollis ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent tincidunt ullamcorper nisi, eget molestie metus.

Nulla consectetur dolor eu diam mollis tempus. Nunc rhoncus congue enim, in sagittis leo aliquet ac. Cras consectetur, erat quis blandit faucibus, libero mauris maximus dolor, non varius nisi tellus et nibh. Praesent non elit mi. Nam lobortis pulvinar dui, congue cursus justo pretium ut. Pellentesque volutpat ut nulla at vestibulum. In at sem suscipit, finibus eros eget, tristique tellus. Suspendisse varius a tortor vel cursus. Nullam sodales ac orci a tempus. Nunc luctus lacinia odio, ac volutpat neque pretium et. Nunc scelerisque posuere erat. Donec nulla neque, convallis a volutpat in, lacinia non purus.

Nunc nec semper justo, nec sodales nisl. Maecenas porttitor, arcu eu efficitur scelerisque, lacus neque pellentesque mi, eu lacinia risus est ac nunc. Cras id porttitor leo, sed dapibus mauris. Cras porttitor justo ut arcu rutrum fringilla. In mattis venenatis ipsum vel ultricies. Aenean consectetur odio non est pretium semper. Fusce rhoncus eros nisl, vel euismod dolor egestas ullamcorper. Aliquam erat volutpat. Aliquam blandit urna ac massa euismod, quis auctor dui ultrices. Donec pretium enim ac rhoncus commodo. Etiam convallis lorem eget mi ornare, non varius neque hendrerit.

Sed mauris ex, euismod ultrices dui vel, maximus facilisis leo. Vestibulum viverra pellentesque tempus. Aliquam in sapien in elit pretium aliquam vel sed lectus. Nunc at elementum felis. Sed vel diam non est suscipit fringilla. Phasellus rutrum, elit eu consectetur tempus, nisl metus tincidunt nisi, ut ultrices risus felis non purus. Duis in ex vitae ex porta mollis. In leo purus, fringilla sit amet lacinia non, iaculis ut dolor. 
            </InfoBanner>
        }
        <div className="footer">
            <div className="holder">
                <div className="footerBlock">
                    <h2 onClick={() => navigate("/kurz")}>
                        Kurz
                    </h2>
                    <h2 onClick={() => navigate("/kontakty")}>
                        Kontakty
                    </h2>
                    <h2 onClick={() => setBanner(banners.businessConditions)}>
                        Obchodní podmínky
                    </h2>
                    <h2 onClick={() => setBanner(banners.gdpr)}>
                        GDPR
                    </h2>
                </div>
                <img src="/images/components/arrow.png" className="arrow" alt="" onClick={() =>  window.scrollTo(0, 0)}/>
            </div>
        </div>
        </>
    );
}
