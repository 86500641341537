import { useEffect, useState } from 'react';
import ProgressCircle from './ProgressCircle';
import './styles/Subject.scss'

export default function Subject({img, progress, title, onClick, mobile = false} : any) {
    const [prog, setProg] = useState(progress);

    useEffect(() => {
        setProg(progress);
    }, [progress])

    return (
        <>
        {!mobile ? 
            <div onClick={onClick} className="subject" >
                <div className="contrast" >
                    <img src={img} alt="" />
                </div>
                <div className="main" >
                    <h1>
                        {title}
                    </h1>
                    <ProgressCircle value={prog} />
                </div>
            </div>
        :
            <div onClick={onClick} className="subject mobile">
                <h1>
                    {title}
                </h1>
                <ProgressCircle value={prog} />
            </div>
        }
        </>
    );
}
